<li class="nav-item nav-search">
  <!-- Search icon -->
 <!--  <a class="nav-link nav-link-search" (click)="toggleSearch()"><span [data-feather]="'search'"
          [class]="'ficon'"></span></a> -->
  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
      <!-- Search Input -->
  <!-- Search box -->
  <div class="search-input" [class.open]="true">
    <div class="search-input-icon"><span [data-feather]="'search'" [class]="'ficon'"></span></div>
    <input class="form-control input"
           (input)="searchTerm$.next($event.target.value)"
           (keyup)="searchUpdate($event)"
           [(ngModel)]="searchText"
           placeholder="ابحث عن استمارة بيع , فاتورة شراء , مادة ...."
           tabindex="-1"
           #openSearch
           />
           <button class="btn search-input-close p-0" (click)="clearInput()"><span [data-feather]="'x'"></span></button>
           <!-- Search List -->

    <!-- Search Results -->
    <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
      <!-- Ordered sections -->
      <ng-container *ngFor="let section of ['customers', 'installments', 'goodss', 'items', 'buyReceipts']">
        <ng-container *ngIf="searchResults?.[section]?.length">
          <li class="d-flex align-items-center">
              <a href="javascript:void(0)" class="w-100">
                  <h6 class="section-label mt-75 mb-1">{{getGroupTitle(section)}}</h6>
              </a>
          </li>
          <ng-container *ngFor="let item of searchResults[section]">
              <li class="auto-suggestion">
                  <a class="d-flex align-items-center justify-content-between py-50 w-100"
                     (click)="toggleSearch();getRouteLink(item, section)">
                      <div class="d-flex align-items-center">
                          <div class="avatar mr-75 d-flex align-items-center justify-content-center">
                              <i [data-feather]="getIcon(section)" class="mr-25" height="20"></i>
                          </div>
                          <div class="search-data">
                              <p class="font search-data-title mb-0 d-flex align-items-center">
                                {{ item.text }}
                                <span class="font" *ngIf="section === 'customers'" >
                                  {{ item.name }}
                                </span>
                              </p>
                          </div>
                      </div>
                  </a>
              </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- Loading and empty states -->
      <li class="auto-suggestion justify-content-between" *ngIf="isLoading">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
              <div class="d-flex justify-content-start">
                  <i data-feather="loader" class="mr-25 spinner"></i>
                  <span class="font" style="padding-right:4px">جاري البحث...</span>
              </div>
          </a>
      </li>
      <li class="auto-suggestion justify-content-between" *ngIf="!isLoading && searchText && (!searchResults?.buyReceipts?.length && !searchResults?.customers?.length && !searchResults?.goodss?.length && !searchResults?.installments?.length && !searchResults?.items?.length)">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
              <div class="d-flex justify-content-start">
                  <i data-feather="alert-circle" class="mr-25"></i>
                  <span class="font" style="padding-right:4px">لا توجد نتائج</span>
              </div>
          </a>
      </li>
    </ul>
  </div>




</div>
