import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  template: `
    <ng-container style="width: 100%;">
      <tr *ngFor="let _ of rowArray"  >
        <td *ngFor="let _ of columnArray">
          <ngx-skeleton-loader appearance="line" [count]="1" ></ngx-skeleton-loader>
        </td>
      </tr>
    </ng-container>
  `,
  styles: [`
    ngx-skeleton-loader {
      width: 100%;
      height: 20px;
      margin-bottom: 10px;
    }

  `]
})
export class SkeletonLoaderComponent {
  @Input() count: number = 6;
  @Input() rows: number = 14;

  get columnArray(): any[] {
    return new Array(this.count);
  }

  get rowArray(): any[] {
    return new Array(this.rows);
  }
}
