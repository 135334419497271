<!-- customers-select.component.html -->
<div class="select-container">
  <ng-select
    [items]="customers"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [multiple]="multiple"
    [loading]="customersLoading"
    [placeholder]="placeholder"
    [typeahead]="searchInput$"
    [virtualScroll]="true"
    appendTo="body"
    (change)="selectingItem($event)"
    (search)="onSearch($event)"
    (clear)="onClear()"
    (scrollToEnd)="paginate()"
    [ngModel]="selectedItem"
    [readonly]="readonly"
    dropdownPosition="auto">

    <!-- Custom dropdown template to ensure ID results display properly -->
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <div class="customer-option">
        <div class="customer-name">{{ item[bindLabel] }}</div>
        <div class="customer-id" *ngIf="item.id">رقم العضوية: {{ item.id }}</div>
       </div>
    </ng-template>

    <!-- Custom selected item template -->
    <ng-template ng-label-tmp let-item="item">
      {{ item[bindLabel] }}
    </ng-template>

    <!-- Empty results template -->
    <ng-template ng-notfound-tmp>
      <div class="empty-message">لا توجد نتائج</div>
    </ng-template>

    <!-- Loading template -->
    <ng-template ng-loadingtext-tmp>
      <div class="loading-message">جاري البحث...</div>
    </ng-template>
  </ng-select>
</div>
