import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';


const appRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  }, {
    path: 'bails',
    loadChildren: () => import('./main/AlroiaInstallments/bails/bails.module').then(m => m.BailsModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('./main/AlroiaInstallments/branches/branches.module').then(m => m.BranchesModule)
  },
  {
    path: 'buyreceipts',
    loadChildren: () => import('./main/AlroiaInstallments/buyreceipts/buyreceipts.module').then(m => m.BuyReceiptsModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./main/AlroiaInstallments/careers/careers.module').then(m => m.CareersModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./main/AlroiaInstallments/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'customerClasses',
    loadChildren: () => import('./main/AlroiaInstallments/customerClasses/customerClasses.module').then(m => m.customerClassesModule)
  },
  {
    path: 'customerProgresses',
    loadChildren: () => import('./main/AlroiaInstallments/customerProgresses/customerProgresses.module').then(m => m.CustomerProgressesModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./main/AlroiaInstallments/customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'employers',
    loadChildren: () => import('./main/AlroiaInstallments/employers/employers.module').then(m => m.EmployersModule)
  },
  {
    path: 'goodss',
    loadChildren: () => import('./main/AlroiaInstallments/goodss/goodss.module').then(m => m.GoodssModule)
  },
  {
    path: 'installments',
    loadChildren: () => import('./main/AlroiaInstallments/installments/installments.module').then(m => m.InstallmentsModule)
  },

  {
    path: 'installmentItems',
    loadChildren: () => import('./main/AlroiaInstallments/installmentItems/installmentItems.module').then(m => m.InstallmentItemsModule)
  },
  {
    path: 'installmentPayments',
    loadChildren: () => import('./main/AlroiaInstallments/installmentPayments/installmentPayments.module').then(m => m.InstallmentPaymentsModule)
  },
  {
    path: 'journals',
    loadChildren: () => import('./main/AlroiaInstallments/Journals/Journals.module').then(m => m.JournalsModule)
  }, {
    path: 'items',
    loadChildren: () => import('./main/AlroiaInstallments/items/items.module').then(m => m.ItemsModule)
  },
  {
    path: 'notes',
    loadChildren: () => import('./main/AlroiaInstallments/notes/notes.module').then(m => m.NotesModule)
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./main/AlroiaInstallments/suppliers/suppliers.module').then(m => m.SuppliersModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('./main/AlroiaInstallments/templates/templates.module').then(m => m.TemplatesModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('./main/AlroiaInstallments/transactionGroups/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'branches-logs',
    loadChildren: () => import('./main/AlroiaInstallments/goodssTransformforTrx/goodsTransformTrx.module').then(m => m.GoodsTransformTrxModule)
  },
  {
    path: 'activity-logs',
    loadChildren: () => import('./main/AlroiaInstallments/activityLogs/activityLogs.module').then(m => m.ActivityLogsModule)
  },//
  {
    path: 'financial-transactions',
    loadChildren: () => import('./main/AlroiaInstallments/financialTransations/financialTransactions.module').then(m => m.FinancialTransationsModule)
  }, {
    path: 'users',
    loadChildren: () => import('./main/AlroiaInstallments/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'my-entries',
    loadChildren: () => import('./main/AlroiaInstallments/myEntries/myEntries.module').then(m => m.MyEntriesModule)
  },
  {
    path: 'my-activity-logs',
    loadChildren: () => import('./main/AlroiaInstallments/myActivityLogs/myActivityLogs.module').then(m => m.MyActivityLogsModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('./main/AlroiaInstallments/roles/roles.module').then(m => m.RolesModule)
  }, {
    path: 'reports',
    loadChildren: () => import('./main/AlroiaInstallments/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/dashboard/ecommerce',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
