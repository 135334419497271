import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.roles === Role.Admin;
  }

  IsUserHasClaim(claimName: string) {
    let res = JSON.parse(localStorage.getItem('currentUser')).claims;
    return res.find(c => c.type == claimName && c.value == "Write") ? true : false;
  }

  getUserBranchId(){
    return JSON.parse(localStorage.getItem('currentUser'))?.branchId;
  }


  IsUserHasReadClaim(claimName: string) {
    let res = JSON.parse(localStorage.getItem('currentUser')).claims;
    return res.find(c => c.type == claimName && c.value == "Read") ? true : false;
  }


  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.roles === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/Auth/Login`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in  ' +
                '  Alroia System. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + user.userName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);
            // notify
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }




  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  getClaimArName(name) {

    if (name == "Users")
      return "المستخدمين"


    if (name == "Branches")
      return "الفروع"


    if (name == "Careers")
      return "المهن"


    if (name == "Customers")
      return "الزبائن"

    if (name == "Suppliers")
      return "المجهزين"

    if (name == "Items")
      return "المواد"

    if (name == "Notes")
      return "الملاحظات"

    if (name == "Templates")
      return "القوالب"

    if (name == "Accounting")
      return "الحسابات"

    if (name == "BuyReceipts")
      return "فواتير الشراء"

    if (name == "CustomerClasses")
      return "الاصناف"

    if (name == "Employers")
      return "أرباب العمل"

    if (name == "ActivityLogs")
      return "حركات المستخدمين"

    if (name == "Goodss")
      return "البضائع"

    if (name == "Categories")
      return "الاصناف"


    if (name == "Installments")
      return "الاقساط"

    if (name == "InstallmentUpdate")
      return "تحديث الاقساط"

    if (name == "CustomerLimit")
      return "حدود الزبون"

        if (name == "CustomerProgresses")
      return "متابعة الزبون"
  }


  getRoles() {
    return this._http.get(`${environment.apiUrl}/api/Auth/Roles`);
  }

  getClaims(): any {
    return this._http.get(`${environment.apiUrl}/api/Claims`);
  }

  getUserCalims(userId: string) {
    return this._http.get(`${environment.apiUrl}/api/Claims/UserCalims?userId=${userId}`);
  }

  getRoleCalims(userId: string) {
    return this._http.get(`${environment.apiUrl}/api/Claims/Roles?userId=${userId}`);
  }
  addUserCalims(data: object) {
    return this._http.post(`${environment.apiUrl}/api/Claims/AddClaimToUser`, data);
  }

  addRoleToCalims(data: object) {
    return this._http.post(`${environment.apiUrl}/api/Claims/AddClaimToRole`, data);
  }


  deleteUserCalims(data: object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this._http.delete(`${environment.apiUrl}/api/Claims/RemoveClaim`, options)
  }

  deleteRoleCalims(data: object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this._http.delete(`${environment.apiUrl}/api/Claims/RemoveClaimFromRole`, options)
  }


  addRoleClaimsToUser(obj) {
    return this._http.post(`${environment.apiUrl}/api/Claims/AddRoleClaimsToUser`, obj);
  }

  clearAllClaims(obj) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: new String(obj)
    };
    //console.log(obj);
    return this._http.delete(`${environment.apiUrl}/api/Claims/RemoveAllClaims`, options);
  }



  resetPassword(obj) {
    return this._http.post(`${environment.apiUrl}/api/Auth/ResetPassword`, obj);
  }

}
