import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';

import { SearchService } from 'app/layout/components/navbar/navbar-search/search.service';
import { ReportsService, SearchResult } from '@core/services/reports.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface OrderedSearchResult {
  customers: any[];
  installments: any[];
  goodss: any[];
  items: any[];
  buyReceipts: any[];
}

@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html',
  styleUrls: ['./navbar-search.component.scss']
})
export class NavbarSearchComponent implements OnInit {
  // Public
  public searchText = '';
  public openSearchRef = true;
  public activeIndex = 0;
  public apiData;
  public pages = [];
  public files = [];
  public contacts = [];
  public pageSearchLimit;
  public searchTerm$ = new Subject<string>();
  public groupedResults: { [key: string]: any[] } = {};
  public isLoading = false;

  // Decorators
  @ViewChild('openSearch') private _inputElement: ElementRef;
  @ViewChild('pageList') private _pageListElement: ElementRef;
  searchResults = null;

  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openSearchRef = false;
    this.searchText = '';
  }
  @HostListener('document:click', ['$event']) clickout(event) {
    if (event.target.className === 'content-overlay') {
   //   this.removeOverlay();
    //  this.openSearchRef = false;
   //   this.searchText = '';
    }
  }

  /**
   *
   * @param document
   * @param router
   * @param _searchService
   */
  constructor(
    @Inject(DOCUMENT) private document,
    private _elementRef: ElementRef,
    private router: Router,
    public _searchService: SearchService,
    public _reportService: ReportsService
  ) {

  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  /**
   * Auto Suggestion
   *
   * @param event
   */
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      let current_item = this._pageListElement.nativeElement.getElementsByClassName('current_item');
      current_item[0]?.children[0].click();
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this._searchService.onIsBookmarkOpenChange.next(false);
    this.removeOverlay();
    this.clearInput();
   // this.openSearchRef = this.openSearchRef;
// this.activeIndex = 0;
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    });

    if (this.openSearchRef === false) {
     // this.document.querySelector('.app-content').classList.remove('show-overlay');
     // this.searchText = '';
    }
  }

  /**
   * Search Update
   *
   * @param event
   */
  searchUpdate(event) {
    const val = event.target.value.toLowerCase();
    if (val !== '') {
      this.searchResults = null;
      this.document.querySelector('.app-content').classList.add('show-overlay');
      this.isLoading = true;
      this.searchTerm$.next(val); // This will be debounced by 800ms
    } else {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
      this.searchResults = null;
      this.isLoading = false;
    }
  }

  /**
   * Clear Input
   */
  clearInput(): void {
    this.searchText = '';
    this.searchResults = null;
    this.isLoading = false;
    this.searchTerm$.next('');
    this.document.querySelector('.app-content').classList.remove('show-overlay');
    this._inputElement.nativeElement.focus();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._reportService.quickSearch(this.searchTerm$.pipe(
      debounceTime(800), // Wait 800ms between keystrokes before making API call
      distinctUntilChanged()
    ))
    .subscribe((results: SearchResult) => {
      // Create array of ordered sections
      const orderedSections = [
        'customers',
        'installments',
        'goodss',
        'items',
        'buyReceipts'
      ];

      // Build ordered results object
      this.searchResults = orderedSections.reduce((acc, key) => {
        if (results?.[key]?.length > 0) {
          acc[key] = results[key];
        }
        return acc;
      }, {});

      this.isLoading = false;
      this.activeIndex = 0;
    });

    this._searchService.onApiDataChange.subscribe(res => {
      this.apiData = res;
      this.pages = this.apiData[0].data;
      this.pageSearchLimit = this.apiData[0].searchLimit;
      this.files = this.apiData[1].data;
      this.contacts = this.apiData[2].data;
    });
  }


  getIcon(type: string): string {
    const icons = {
      'customers': 'user',
      'items': 'box',
      'buyReceipts': 'file-text',
      'goodss': 'package',
      'installments': 'credit-card'
    };
    return icons[type] || 'help-circle';
  }

  getGroupTitle(key: string): string {
    const titles = {
      'customers': 'الزبائن',
      'items': 'المواد',
      'buyReceipts': 'فواتير الشراء',
      'goodss': 'البضائع',
      'installments': 'الأقساط'
    };
    return titles[key] || key;
  }

  getRouteLink(item: any, type: string): void {
    const routes = {
      'customers': ['/installmentItems/installment-paying?id=' + item.id],
      'items': ['items/details', item.id],
      'buyReceipts': ['buyreceipts?id=' + item.id],
      'goodss': ['buyreceipts?id=' + item.id],
      'installments': ['installmentItems/add?id=' + item.id]
    };

    if (type === 'customers') {
      this.router.navigate(['installmentItems/installment-paying'], {
        queryParams: { id: item.id }
      });
      this.removeOverlay();
      return;
    }
    if (type === 'buyReceipts') {
      this.router.navigate(['buyreceipts'], {
        queryParams: { search: item.id }
      });
      this.removeOverlay();
      return;
    }
    if ( type === 'goodss') {
      this.router.navigate(['buyreceipts'], {
        queryParams: { barcode: item.id }
      });
      this.removeOverlay();
      return;
    }
    if (type === 'installments') {
      this.router.navigate(['installmentItems/add'], {
        queryParams: { search: item.id }
      });
      this.removeOverlay();
      return;
    }
    if (type === 'items') {
      this.router.navigate(['items'], {
        queryParams: { search: item.id }
      });
      this.removeOverlay();
      return;
    }
    const url = routes[type]?.[0] || '/';
    window.open(url, '_blank');
  }

}
