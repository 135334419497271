import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';

@NgModule({
  declarations: [SkeletonLoaderComponent],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,

  ],
  exports: [
    SkeletonLoaderComponent,
   ]
})
export class SharedModule { }
