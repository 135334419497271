import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  SimpleChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomersService } from '@core/services/customers.service';
import { Customer, CustomerFilterData } from '@core/types/customers';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customers-select',
  templateUrl: './customers-select.component.html',
  styleUrls: ['./customers-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomeraSelectComponent),
      multi: true,
    },
  ],
})
export class CustomeraSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'id';
  @Input() unitType: any = null;
  @Input() id: string = '';
  @Input() multiple: boolean = false;
  @Input() type: string = '';
  @Input() customer: Customer ;
  @Input() readonly:boolean = false;

  @Output() modelChange = new EventEmitter<boolean>();

  searchInput$ = new Subject<string>();
  private destroy$ = new Subject<void>();
  private initialized = false;

  selectedItem: any;
  searchForm = {
    pageNumber: 1,
    pageSize: 10,
    name: '',
    filterParam: {},
  };
  customers: any[] = [];
  customersLoading: boolean = false;
  filterParam = {} as CustomerFilterData;
  searchTimeout: any = null;
  placeholder: string = 'بحث عن الزبون ....';

  constructor(private _customersService: CustomersService) { }

  writeValue(value: any): void {
    if (value !== this.selectedItem) {
      this.selectedItem = value;
      if (value && !this.customers.find(c => c.id === value) && !this.initialized) {
        this.getUnitById(value);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.setPlaceholderByType();

    // Set up the searchInput$ subscription
    this.searchInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(term => {
      this.filterParam.searchText = term;
      this.getUnits(true);
    });

    // Only initialize once with either customer or id
    if (this.customer && !this.initialized) {
      this.initialized = true;
      this.customers = [this.customer];
      this.selectedItem = this.customer?.id;
      this.onChange(this.customer?.id);
    } else if (this.id && !this.initialized) {
      this.initialized = true;
      this.handleIdChange(this.id);
    } else if (!this.initialized && !this.customer && !this.id) {
      this.initialized = true;
      this.getUnits();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  setPlaceholderByType(): void {
    switch (this.type) {
      case 'Id':
        this.placeholder = 'أسم و رقم العضوية بحث عن الزبون ....';
        break;
        case 'Patron':
          this.placeholder = 'أسم و رقم العضوية بحث عن الكفيل ....';
          this.filterParam.isPatron = true
          break;
      case 'Mobile':
        this.placeholder = 'بحث عن الزبون رقم الهاتف....';
        break;
      case 'Name':
      default:
        this.placeholder = 'بحث عن الزبون ....';
        break;
    }
  }

  getSelectedTitle(): string {
    if (!this.selectedItem || !this.customers) return '';

    if (this.multiple) {
      const selectedUnits = this.customers.filter(unit =>
        this.selectedItem.includes(unit[this.bindValue])
      );
      return selectedUnits.map(unit => unit[this.bindLabel]).join(', ');
    } else {
      const selectedUnit = this.customers.find(unit =>
        unit[this.bindValue] === this.selectedItem
      );
      return selectedUnit ? selectedUnit[this.bindLabel] : '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'] && !changes['id'].firstChange) {
      const idValue = changes['id'].currentValue;
      if (idValue !== this.selectedItem) {
        this.handleIdChange(idValue);
      }
    }
    if (changes['customer'] && !changes['customer'].firstChange) {
      const newCustomer = changes['customer'].currentValue;
      if (newCustomer && newCustomer.id !== this.selectedItem) {
        this.customers = [newCustomer];
        this.selectedItem = newCustomer.id;
        this.onChange(newCustomer.id);
      }
    }
  }

  handleIdChange(id: string | null): void {
    if (id != null) {
      this.getUnitById(id);
    } else {
      this.selectedItem = null;
    }
  }

  getUnits(resetList: boolean = false) {
    if (resetList) {
      this.customers = [];
      this.searchForm.pageNumber = 1;
    }

    this.updateFilterParamsByType();

    this.customersLoading = true;
    this._customersService.searchCustomers(
      this.filterParam,
      this.searchForm.pageNumber,
      this.searchForm.pageSize,
    ).subscribe(
      (res: any) => {
        if (res?.items?.length) {
          this.customers = resetList
            ? [...res.items]
            : [...this.customers, ...res.items];
        }
        this.customersLoading = false;
      },
      (error) => {
        console.error('Error fetching customers:', error);
        this.customersLoading = false;
      }
    );
  }

  updateFilterParamsByType(): void {
    this.filterParam.Id = null;
    this.filterParam.Name = null;
    this.filterParam.Mobile = null;

    const searchText = this.filterParam.searchText || '';
    const isNumber = !isNaN(Number(searchText)) && searchText.trim() !== '';

    switch (this.type) {
      case 'Id':
        if (isNumber) {
          this.filterParam.Id = searchText;
        } else {
          this.filterParam.Name = searchText;
        }
        break;
      case 'Mobile':
        this.filterParam.Mobile = searchText;
        break;
      case 'Name':
      default:
        if (isNumber) {
          this.filterParam.Id = searchText;
        } else {
          this.filterParam.Name = searchText;
        }
        break;
    }
  }

  selectingItem(e: any) {
    if (this.multiple) {
      this.selectedItem = e ? e.map((item: any) => item[this.bindValue]) : [];
    } else {
      this.selectedItem = e ? e[this.bindValue] : null;
    }
    this.onChange(this.selectedItem);

    if (e) {
      this.modelChange.emit(e);
    }
  }

  onClear() {
    this.selectedItem = null;
    this.selectingItem(null);
    this.modelChange.emit(null);
  }

  onSearch(e: any) {
    this.searchInput$.next(e?.term || '');
  }

  paginate() {
    if (!this.customersLoading) {
      this.searchForm.pageNumber += 1;
      this.getUnits(false);
    }
  }

  getUnitById(id: any) {
    if (!id) return;

    this._customersService.getCustomer(id).subscribe(
      (res: any) => {
        if (res) {
          const customerData = res;
          const exists = this.customers.some(c => c[this.bindValue] === customerData[this.bindValue]);
          if (!exists) {
            this.customers = [customerData];
          }
          this.selectedItem = customerData[this.bindValue];
          this.onChange(this.selectedItem);
        }
      },
      (error) => {
        console.error('Error fetching customer by ID:', error);
      }
    );
  }

  private onChange = (value: any) => { };
  private onTouched = () => { };
}
