import { HttpClient, HttpParams } from '@angular/common/http';
import { AccountFilterParams, AccountTansationParams, GeneralReportarams, TransactionGroup, TransactionGroups } from '@core/types/transactionGroups';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ConfigService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private _apiBaseUrl: string;

  constructor(private _httpClient: HttpClient, private _configService: ConfigService) {
    this._apiBaseUrl = this._configService.getBaseUrl();
  }


  getGeneralReport(filterParams = {} as GeneralReportarams) {
    let params = new HttpParams();

    if (filterParams.From) {
      params = params.set('from', filterParams.From);
    }

    if (filterParams.To) {
      params = params.set('to', filterParams.To);
    }
    if (filterParams.BranchId) {
      params = params.set('branchId', filterParams.BranchId);
    }
    return this._httpClient.get(`${this._apiBaseUrl}/api/Reports/GeneralReport?${params}`);
  }



  getUserReport(filterParams = {} as GeneralReportarams) {
    let params = new HttpParams();

    if (filterParams.From) {
      params = params.set('from', filterParams.From);
    }

    if (filterParams.To) {
      params = params.set('to', filterParams.To);
    }
    if (filterParams.BranchId) {
      params = params.set('branchId', filterParams.BranchId);
    }
    return this._httpClient.get(`${this._apiBaseUrl}/api/Reports/UserReport?${params}`);
  }


  getItemsReport(filterParams = {} as GeneralReportarams) {
    let params = new HttpParams();

    if (filterParams.From) {
      params = params.set('from', filterParams.From);
    }

    if (filterParams.To) {
      params = params.set('to', filterParams.To);
    }
    if (filterParams.BranchId) {
      params = params.set('branchId', filterParams.BranchId);
    }

    if (filterParams.PageNumber) {
      params = params.set('PageNumber', filterParams.PageNumber);
    }
    if (filterParams.PageSize) {
      params = params.set('PageSize', filterParams.PageSize);
    }


    return this._httpClient.get(`${this._apiBaseUrl}/api/Reports/ItemsReport?${params}`);
  }



  quickSearch(terms: Observable<string>): Observable<SearchResult> {
    return terms.pipe(debounceTime(700))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => this.searchEntriesQuick(term)));
  }

  searchEntriesQuick(term)  {
    if (term) {
      return this._httpClient
        .get(`${this._apiBaseUrl}/api/Reports/quickSearch?search=${term}`)
        .pipe(map(res => res));
    }
    return [];
  }

  search(terms: Observable<string>, payable = false, isSupportAcc?) {
    let type = isSupportAcc ? 'Liabilities21' : '';
    return terms.pipe(debounceTime(700))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => this.searchEntries(term, payable, type)));
  }

  searchEntries(term, payable, type) {
    if (term) {
      return this._httpClient
        .get(`${this._apiBaseUrl}/api/Accounts?Name=${term}&Payable=${payable}&Type=${type}`)
        .pipe(map(res => res));
    }
    return [];
  }
}

export interface SearchResult {
  customers: any[];
  installments: any[];
  goodss: any[];
  items: any[];
  buyReceipts: any[];

  // other properties in your desired order
}

