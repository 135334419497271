<div *ngIf="!isProduction" class="testHighlight">

</div>

<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item">
            <a class="navbar-brand" [routerLink]="['/']">
                <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
                <h2 class="brand-text mb-0">{{ appName }}</h2>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->
    </div>
    <ul class="nav navbar-nav align-items-center ml-auto">
        <!-- Language selection -->


        <!-- Toggle skin -->
        <li class="nav-item d-none d-lg-block">
            <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </li>
        <!--/ Toggle skin -->

        <!-- Search -->
        <app-navbar-search></app-navbar-search>
        <!--/ Search -->



        <!-- Notification -->
<!--         <app-navbar-notification></app-navbar-notification>
 -->        <!--/ Notification -->

        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user">
            <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
                <ng-container *ngIf="this.currentUser">
                    <div class="user-nav d-sm-flex d-none">
                        <span class="user-name font-weight-bolder mb-0"> {{ currentUser?.userName}}</span
            >
                    </div>
                    <span class="avatar"><img
              class="round"
              src="./../../../../assets/images/avatar.png"
              alt="avatar"
              height="40"
              width="40" /><span class="avatar-status-online"></span
          ></span>
                </ng-container>
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">

        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
            </div>
        </li>
     </ul>
</div>

<!-- Modal -->
<ng-template #modalForm let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h3 class="modal-title"> يجب تغيير اعدادت اللغه قبل استخدم البرنامج</h3>
    </div>

    <div class="modal-body text-right" dir=" rtl ">

        <h3>
            1- الذهاب الي اعدادات المتصفح
            <br> 2- الذهاب الي قسم اللغات الظاهر في القائمة الجانبية
            <br> 3- اختيار اللغة الانكليزية و اختيار خيار عرض المتصفح باللغة الانكليزية
            <br> 4- اعادة فتح البرنامج بعد تغيير لغة المتصفح
        </h3>
    </div>
    <div class="modal-footer ">
    </div>
</ng-template>
<!-- / Modal -->
