import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Branch, Branches } from '@core/types/branches';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ConfigService } from './settings.service';

@Injectable({
    providedIn: 'root'
  })
  export class ToolsService {

   private _apiBaseUrl: string ;

   constructor(private _httpClient: HttpClient,private _configService: ConfigService) {
    this._apiBaseUrl= this._configService.getBaseUrl();
   }

      getApiVersion(){
      let params = new HttpParams();
       return this._httpClient.get(`${this._apiBaseUrl}/api/Tools/GetVersion`);
  }


 }





