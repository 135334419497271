import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  template: `
    <ng-container style="width: 100%;">
      <tr *ngFor="let _ of rowArray">
        <td *ngFor="let _ of columnArray">
          <ngx-skeleton-loader
            appearance="line"
            [count]="1"
            [theme]="isDarkMode ? darkTheme : lightTheme"
          ></ngx-skeleton-loader>
        </td>
      </tr>
    </ng-container>
  `,
  styles: [`
    ngx-skeleton-loader {
      width: 100%;
    }

    :host-context(.dark-layout) ::ng-deep {
      .progress-linear {
        background: #2f3349 !important;
      }
      .progress {
        background: #383d5d !important;
      }
      ngx-skeleton-loader {
        --progress-bg: #383d5d;
      }
    }
  `]
})
export class SkeletonLoaderComponent {
  @Input() count: number = 6;
  @Input() rows: number = 14;

  darkTheme = {
    'background-color': '#2f3349',
    'margin-bottom': '10px',
    'border-radius': '4px',
    'height': '20px'
  };

  lightTheme = {
    'margin-bottom': '10px',
    'border-radius': '4px',
    'height': '20px'
  };

  get isDarkMode(): boolean {
    return document.body.classList.contains('dark-layout');
  }

  get columnArray(): any[] {
    return new Array(this.count);
  }

  get rowArray(): any[] {
    return new Array(this.rows);
  }
}
