import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [

  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'section',
    icon: 'home',
    translate: 'الاعدادات',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'Users',
        title: 'Users',
        translate: 'المستخدمين',
        type: 'item',
        icon: 'users',
        url: 'users'
      },

      {
        id: 'Users',
        title: 'roles',
        translate: 'الصلاحيات',
        type: 'item',
        icon: 'slack',
        url: 'roles'
      },

    ]
  },
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'section',
    icon: 'home',
    translate: 'الرئيسية',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'Templates',
        title: 'Templates',
        translate: 'قوالب',
        type: 'item',
        icon: 'plus-square',
        url: 'templates'
      },
      {
        id: 'Branches',
        title: 'Branches',
        translate: 'الفروع',
        type: 'item',
        icon: 'layers',
        url: 'branches'
      },
      {
        id: 'Categories',
        title: 'Categories',
        translate: 'الاصناف',
        type: 'item',
        icon: 'grid',
        url: 'categories'
      }
      , {
        id: 'Notes',
        title: 'Notes',
        translate: 'الملاحظات',
        type: 'item',
        icon: 'file-text',
        url: 'notes'
      }
    ]
  },
  {
    id: 'parts',
    title: 'Parts',
    type: 'section',
    icon: 'package',
    translate: 'الاقساط',
    classes: 'badge-light-warning badge-pill',
    children: [

      {
        id: 'Installments',
        title: 'فواتير البيع',
        translate: '  فواتير البيع',
        type: 'item',
        icon: 'file',
        url: 'installmentItems/add'
      },
      {
        id: 'Installments',
        title: 'التسديد',
        translate: 'التسديد',
        type: 'item',
        icon: 'credit-card',
        url: 'installmentItems/installment-paying'
      }, {
        id: 'Installments',
        title: 'المتابعة ',
        translate: 'المتابعة ',
        type: 'item',
        icon: 'user-check',
        url: 'installmentItems/customers-followUp'
      },
    ]
  },
  {
    id: 'customers',
    title: 'Customers',
    type: 'section',
    icon: 'user',
    translate: 'الزبائن',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'Customers',
        title: 'Customers',
        translate: 'الزبائن',
        type: 'item',
        icon: 'user',
        url: 'customers'
      }, {
        id: 'Employers',
        title: 'Employers',
        translate: 'ارباب العمل',
        type: 'item',
        icon: 'user-check',
        url: 'employers'
      },
      {
        id: 'CustomerClasses',
        title: 'CustomerClasses',
        translate: 'اصناف الزبائن',
        type: 'item',
        icon: 'users',
        url: 'customerClasses'
      },
      {
        id: 'Careers',
        title: 'Careers',
        translate: 'المهن',
        type: 'item',
        icon: 'briefcase',
        url: 'careers'
      },
    ]
  }
  ,


  {
    id: 'store',
    title: 'Store',
    type: 'section',
    icon: 'database',
    translate: 'مخزن',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'BuyReceipts',
        title: 'BuyReceipts',
        translate: 'الفواتير',
        type: 'item',
        icon: 'clipboard',
        url: 'buyreceipts'
      },
      {
        id: 'Items',
        title: 'Items',
        translate: 'المواد',
        type: 'item',
        icon: 'package',
        url: 'items'
      },
      {
        id: 'Suppliers',
        title: 'Suppliers',
        translate: 'المجهزين',
        type: 'item',
        icon: 'user',
        url: 'suppliers'
      },
    ]
  }
  ,
  {
    id: 'cash',
    title: 'Cash',
    type: 'section',
    icon: 'credit-card',
    translate: 'حسابات',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'Accounting',
        title: 'AccountsModule',
        translate: 'الحسابات',
        type: 'item',
        icon: 'dollar-sign',
        url: 'accounts'
      },
      {
        id: 'Accounting',
        title: 'financial-transactions',
        translate: '  الحركات المالية',
        type: 'item',
        icon: 'clipboard',
        url: 'financial-transactions'
      },
      {
        id: 'ActivityLogs',
        title: 'TransactionGroups',
        translate: '  حركات المستخدمين',
        type: 'item',
        icon: 'file',
        url: 'activity-logs'
      },
    ]
  },
  {
    id: 'cash',
    title: 'Cash',
    type: 'section',
    icon: 'credit-card',
    translate: '  التقارير',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'Accounting',
        title: 'AccountsModule',
        translate: 'التقرير العام',
        type: 'item',
        icon: 'dollar-sign',
        url: 'reports/general-report'
      },
      {
        id: 'Accounting',
        title: 'AccountsModule',
        translate: 'تقرير المواد',
        type: 'item',
        icon: 'dollar-sign',
        url: 'reports/items-report'
      },

    ]
  },
  {
    id: 'All',
    title: 'Cash',
    type: 'section',
    icon: 'credit-card',
    translate: 'القائمة الشخصية',
    classes: 'badge-light-warning badge-pill',
    children: [
      {
        id: 'All',
        title: 'AccountsModule',
        translate: 'حركاتي المالية',
        type: 'item',
        icon: 'dollar-sign',
        url: 'my-entries'
      },
      {
        id: 'All',
        title: 'AccountsModule',
        translate: '  سجل حركاتي',
        type: 'item',
        icon: 'file',
        url: 'my-activity-logs'
      },{
        id: 'All',
        title: 'AccountsModule',
        translate: 'الفروع',
        type: 'item',
        icon: 'file',
        url: 'branches-logs'
      },
    ]
  },

];
