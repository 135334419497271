import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomersService } from '@core/services/customers.service';
import { CustomerFilterData } from '@core/types/customers';

@Component({
  selector: 'app-customers-select',
  templateUrl: './customers-select.component.html',
  styleUrls: ['./customers-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomeraSelectComponent),
      multi: true,
    },
  ],
})
export class CustomeraSelectComponent implements ControlValueAccessor {
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'id';
  @Input() unitType: any = null;
  @Input() id: string = '';
  @Input() multiple: boolean = false;

  @Output() modelChange = new EventEmitter<boolean>();

  selectedItem: any;
  searchForm = {
    pageNumber: 1,
    pageSize: 10,
    name: '',
    filterParam: {},
  };
  customers: any[] = [];
  customersLoading: boolean = false;
  filterParam = {} as CustomerFilterData;
  isSearching: boolean = false;

  constructor(private _customersService: CustomersService) {}

  writeValue(value: any): void {
    this.selectedItem = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.getUnits();
  }

  getSelectedTitle(): string {
    if (!this.selectedItem || !this.customers) return '';

    if (this.multiple) {
      const selectedUnits = this.customers.filter(unit =>
        this.selectedItem.includes(unit[this.bindValue])
      );
      return selectedUnits.map(unit => unit[this.bindLabel]).join(', ');
    } else {
      const selectedUnit = this.customers.find(unit =>
        unit[this.bindValue] === this.selectedItem
      );
      return selectedUnit ? selectedUnit[this.bindLabel] : '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      const idValue = changes['id'].currentValue;
      this.handleIdChange(idValue);
    }
  }

  handleIdChange(id: string | null): void {
    if (id != null) {
      this.getUnitById(id);
    } else {
      this.selectedItem = null;
    }
  }

  getUnits(resetList: boolean = false) {
    // If resetting the list, clear existing customers and reset page number
    if (resetList) {
      this.customers = [];
      this.searchForm.pageNumber = 1;
    }

    this.customersLoading = true;
    this._customersService.getCustomers(
      this.searchForm.pageNumber,
      this.searchForm.pageSize,
      this.filterParam
    ).subscribe(
      (res: any) => {
        // If paginating, append results; if searching, replace results
        this.customers = resetList
          ? res.items
          : [...this.customers, ...res.items];
        this.customersLoading = false;
      },
      (error) => {
        console.error('Error fetching customers:', error);
        this.customersLoading = false;
      }
    );
  }

  selectingItem(e: any) {
    if (this.multiple) {
      this.selectedItem = e ? e.map((item: any) => item[this.bindValue]) : [];
    } else {
      this.selectedItem = e ? e[this.bindValue] : null;
    }
    this.onChange(this.selectedItem);

    if (e) {
      this.modelChange.emit(e);
    }
  }

  onClear() {
    this.selectedItem = null;
    this.selectingItem(null);
    this.modelChange.emit(null);
  }

  onSearch(e: any) {
    // Clear the timeout if it exists
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;
    this.filterParam.searchBy = "name";
    this.filterParam.searchText = e?.term || null;

    // Debounce the search to prevent multiple rapid requests
    setTimeout(() => {
      this.getUnits(true); // true indicates to reset the list
      this.isSearching = false;
    }, 300);
  }

  paginate() {
    if (!this.customersLoading) {
      this.searchForm.pageNumber += 1;
      this.getUnits(false); // false indicates to append to existing list
    }
  }

  getUnitById(id: any) {
    if (!id) return;

    this._customersService.getCustomer(id).subscribe(
      (res: any) => {
        if (res?.data) {
          // Only update if we got valid data
          this.customers = [res.data];
          this.writeValue(res.data.id);
        }
      },
      (error) => {
        console.error('Error fetching customer by ID:', error);
      }
    );
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};
}
