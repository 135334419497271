<!-- Footer -->
<p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright  &copy; {{ year }}
    <a class="ml-25" href="#" target="_blank">AlroiaTech</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved  FE {{version}} - BE {{ versionApi}} </span>
    </span>
    <span class="float-md-right d-none d-md-block">
   </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
<!-- Buynow Button-->
<div class="buy-now">
    <a href="#" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow">Buy Now</a>
</div>
