import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityLogFilterData, Customer, CustomerFilterData, Customers } from '@core/types/customers';
 import { Observable, observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ConfigService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private _apiBaseUrl: string;

  constructor(private _httpClient: HttpClient, private _configService: ConfigService) {
    this._apiBaseUrl = this._configService.getBaseUrl();

  }

  getCustomers(pageNumber = 1, pageSize = 200, filterParam = {} as CustomerFilterData): Observable<Customers> {
    let params = new HttpParams();

    if (filterParam.classId)
      params = params.set('classId', filterParam.classId);



    if (filterParam.DedicatedId)
      params = params.set('DedicatedId', filterParam.DedicatedId);


    if (filterParam.branchId)
      params = params.set('branchId', filterParam.branchId);

    if (filterParam.bailId)
      params = params.set('bailId', filterParam.bailId);

    if (filterParam.searchBy == "name")
      params = params.set('Name', filterParam.searchText);

    if (filterParam.searchBy == "DedicatedId")
      params = params.set('DedicatedId', filterParam.searchText);

    if (filterParam.searchBy == "Mobile")
      params = params.set('Mobile', filterParam.searchText);

    if (filterParam.searchBy == "PatronName")
      params = params.set('PatronName', filterParam.searchText);

    if (filterParam.searchBy == "PatronMobile")
      params = params.set('PatronMobile', filterParam.searchText);

    if (filterParam.searchBy == "WorkAddress")
      params = params.set('WorkAddress', filterParam.searchText);

    if (filterParam.sortType)
      params = params.set('SortType', filterParam.sortType);

    if (filterParam.sortBy)
      params = params.set('sortBy', filterParam.sortBy);

    params = params.set('pageNumber', pageNumber.toString());
    params = params.set('pageSize', pageSize.toString());
    return this._httpClient.get<Customers>(`${this._apiBaseUrl}/api/Customers?${params}`);
  }

  getActitvtyLogs(pageNumber = 1, pageSize = 20, filterParam?: ActivityLogFilterData) {

    let params = new HttpParams();

    if (filterParam.From)
      params = params.set('From', filterParam.From);


    if (filterParam.To)
      params = params.set('To', filterParam.To);

    if (filterParam.ActivityType)
      params = params.set('ActivityType', filterParam.ActivityType);



    if (filterParam.EntityId)
      params = params.set('EntityId', filterParam.EntityId);


    if (filterParam.EntityName)
      params = params.set('EntityName', filterParam.EntityName);

    if (filterParam.UserId)
      params = params.set('UserId', filterParam.UserId);

    if (filterParam.branchId)
      params = params.set('branchId', filterParam.branchId);

    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());

    return this._httpClient.get<Customers>(`${this._apiBaseUrl}/api/ActivityLogs?${params}`);
  }


  getMyActitvtyLogs(pageNumber = 1, pageSize = 20, filterParam?: ActivityLogFilterData) {

    let params = new HttpParams();

    if (filterParam.From)
      params = params.set('From', filterParam.From);


    if (filterParam.To)
      params = params.set('To', filterParam.To);

    if (filterParam.ActivityType)
      params = params.set('ActivityType', filterParam.ActivityType);


    if (filterParam.EntityName)
      params = params.set('EntityName', filterParam.EntityName);

    if (filterParam.EntityId)
      params = params.set('EntityId', filterParam.EntityId);

    if (filterParam.UserId)
      params = params.set('UserId', filterParam.UserId);


    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());

    return this._httpClient.get<Customers>(`${this._apiBaseUrl}/api/ActivityLogs/My?${params}`);
  }

  getEntityNames() {
    return this._httpClient.get<string[]>(`${this._apiBaseUrl}/api/ActivityLogs/EntityNames`);
  }

  searchCustomers(filterParams: CustomerFilterData, pageNumber = 1, pageSize = 20): Observable<any> {
    let params = new HttpParams();

    if (filterParams.Name)
      params = params.set('Name', filterParams.Name);

    if (filterParams.Id)
      params = params.set('Id', filterParams.Id);

    if (filterParams.isPatron)
      params = params.set('isPatron', filterParams.isPatron);

    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());

    return this._httpClient.get<string[]>(`${this._apiBaseUrl}/api/Customers/Quick?${params}`);
  }

  getCustomerslist() {
    return this._httpClient.get<Customers>(`${this._apiBaseUrl}/api/Customers`);
  }


  getCustomer(id) {
    return this._httpClient.get(`${this._apiBaseUrl}/api/Customers/${id}`);
  }

  checkCustomerLimit(id) {
    return this._httpClient.get(`${this._apiBaseUrl}/api/Customers/CheckLimit?id=${id}`);
  }

  getCustomerSummary(id) {
    return this._httpClient.get(`${this._apiBaseUrl}/api/Customers/Digest?id=${id}`);
  }
  addCustomer(data): Observable<any> {
    return this._httpClient.post(`${this._apiBaseUrl}/api/Customers`, data);
  }

  deleteCustomer(id: number): Observable<any> {
    return this._httpClient.delete(`${this._apiBaseUrl}/api/Customers/${id}`);
  }

  updateCustomer(id, data): Observable<Customer> {
    return this._httpClient.put<Customer>(`${this._apiBaseUrl}/api/Customers/${id}`, data);
  }

  updateCustomerImg(data): Observable<Customer> {
    return this._httpClient.post<Customer>(`${this._apiBaseUrl}/api/Customers/UpdateDocument`, data);
  }

  deleteCustomerImg(id, fileType): Observable<Customer> {
    return this._httpClient.delete<Customer>(`${this._apiBaseUrl}/api/Customers/DeleteDocument?id=${id}&fileType=${fileType}`);
  }




}
